.nav_open #header .header .header-right .nav-main {
    left: 0px;
}

.fixed {
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.15);
    -webkit-box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.15);
    padding: 7px 0 !important;
    .header .header-main .logo{
        width: 146px;
    }
    .link-menu{
        padding: 11px 34px;
    }
}

#header {
    float: left;
    width: 100%;
    position: fixed;
    background-color: #ffffff;
    left: 0;
    top: 0;
    z-index: 999;
    transition-duration: 300ms;
    -webkit-transition-duration: 300ms;
    -moz-transition-duration: 300ms;
    transition-property: all;
    -webkit-transition-property: all;
    -moz-transition-property: all;
    transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
    -webkit-transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
    -moz-transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
    padding: 18px 0;
}
.nav_open #header .header .header-right .nav-main {
    
    display: block;
}
.header {
    .header-main {
        position: relative;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        align-items: center;
        justify-content: space-between;
        @include transition(all 0.3s);
        .logo {
            float: left;
            @include transition(all 0.3s);
            width: 227px;
            position: relative;
            top: 0px;
            margin-left: 0;
            z-index: 9;
            a {
                width: auto;
                float: left;
                width: auto;
                img {
                    margin: 0 auto;
                    display: inline-block;
                }
            }
        }
    }
    .header-right {
        float: none;
        position: relative;
        z-index: 9;
        .nav-main {
            justify-content: center;
            width: 100% !important;
            // @include transition(all 0.3s);
            ul.menu {
                // width: 100%;
                height: auto;
                @include transition(all 0.3s);
                .menu-item-39 ul.sub-menu {
                    width: 150px;
                }
                li {
                    float: left;
                    width: auto;
                    position: relative;
                    margin: 0 40px;
                    padding: 0 !important;
                    &:hover{
                        &:after{
                            content: '';
                            background-color: #009DA5;
                            position: absolute;
                            bottom: 0;
                            left: 0;
                            width: 100%;
                            height: 2px;
                        }
                        a{
                            color: #009DA5;
                        }
                    }
                    // margin: 0px 10px;
                    &:last-child {
                        // padding-right: 0;
                        &::after {
                            display: none;
                        }
                    }
                    // &::after{
                    // 	background: #EBEBEB;
                    // 	position: absolute;
                    // 	width: 1px;
                    // 	height: 100%;
                    // 	content: '';
                    // 	right: 0;
                    // 	top: 0;
                    // }
                    &.current-menu-item {
                        a {
                            color: #DF091D;
                        }
                    }
                    a {
                        float: left;
                        width: auto;
                        height: auto;
                        padding: 2px 0;
                        font-weight: 600;
                        line-height: normal;
                        letter-spacing: 0.34px;
                        font-size: 17px;
                        line-height: 120%;
                        color: #001919;
                        text-decoration: none;
                        text-transform: capitalize;
                        @include transition(all 0.25s ease 0s);
                        position: relative;
                        overflow: hidden;
                        letter-spacing: 0.5px;
                    }
                    .hover {
                        color: #DF091D;
                        background-color: #fff;
                        display: inline-block;
                        position: absolute;
                        top: 2;
                        left: 0;
                        width: 0;
                        overflow-x: hidden;
                        transition: all 0.5s ease;
                        white-space: nowrap;
                        font-weight: 700;
                        overflow: hidden;
                    }
                    &:hover .hover {
                        width: 100%;
                    }
                    ul.sub-menu {
                        background-color: #fff;
                        width: 230px;
                        position: absolute;
                        opacity: 0;
                        visibility: hidden;
                        left: 20px;
                        padding: 0 0;
                        top: 35px;
                        transition: cubic-bezier(0.25, 0.1, 0.32, 0.83) 0.5s;
                        -webkit-transition: cubic-bezier(0.25, 0.1, 0.32, 0.83) 0.3s;
                        -moz-transition: cubic-bezier(0.25, 0.1, 0.32, 0.83) 0.3s;
                        -o-transition: cubic-bezier(0.25, 0.1, 0.32, 0.83) 0.3s;
                        // transform: translate(0,20px);
                        // transition: all 0.2s ease-out;
                        // // -webkit-transition: all 0.5s;
                        // // transition: all 0.5s;
                        box-shadow: 0 0 2px rgb(0 0 0 / 20%);
                        transform-origin: top;
                        li {
                            width: 100%;
                            height: auto;
                            margin: 0 0 0;
                            position: relative;
                            padding: 0px;
                            float: left;
                            text-align: left;
                            border: 0px;
                            // transform: translate3d(0px, -12px, 0px);
                            // -webkit-transform: translate3d(0px, -12px, 0px);
                            // -moz-transform: translate3d(0px, -12px, 0px);
                            a {
                                float: left;
                                background: none;
                                width: 100%;
                                height: auto;
                                color: #001919;
                                line-height: 125%;
                                font-size: 15px;
                                font-weight: 600;
                                text-decoration: none;
                                padding: 15px 30px;
                                text-transform: capitalize;
                                -webkit-transition: all 0.25s;
                                transition: all 0.25s;
                                border-bottom: 1px solid #EEEEEE;
                            }
                        }
                        &:before {
                            content: "";
                            position: absolute;
                            width: 0px;
                            height: 0px;
                            left: 25px;
                            top: -9px;
                            border-left: 10px solid transparent;
                            border-right: 10px solid transparent;
                            border-bottom: 10px solid #000;
                        }
                    }
                    &:hover {
                        ul.sub-menu {
                            visibility: visible;
                            opacity: 1;
                            transform: translate(0, 0);
                            // -webkit-transition: all 0.5s;
                            // transition: all 0.5s;
                            border-radius: 2px;
                        }
                    }
                    &.menu-item-has-children {
                        // padding-right: 20px;
                        background: url('../images/ic-menu-dropdown-arrow-black.svg');
                        background-repeat: no-repeat;
                        background-position: right 1px center;
                        padding-right: 17px !important;
                    }
                }
            }
        }
    }
}



/*-----------------res-nav-Start-------------------*/

.res_navigation {
    display: none;
    width: 72px;
    height: 23px;
    position: absolute;
    right: 0;
    top: 17px;
}

.nav-toggle {
    position: absolute;
    top: 3px;
    right: 0;
    z-index: 999999;
    padding: 5px 30px 13px 0;
    cursor: pointer;
    &:focus {
        outline: none;
    }
    &.active {
        .top {
            -webkit-transform: translateY(12px) translateX(0) rotate(45deg);
            -ms-transform: translateY(12px) translateX(0) rotate(45deg);
            transform: translateY(12px) translateX(0) rotate(45deg);
            background: #333;
        }
        .bottom {
            -webkit-transform: translateY(-7px) translateX(0) rotate(-45deg);
            -ms-transform: translateY(-7px) translateX(0) rotate(-45deg);
            transform: translateY(-7px) translateX(0) rotate(-45deg);
            background: #333;
        }
        .middle {
            opacity: 0;
            background: #FFF;
        }
    }
    span {
        background: #333;
        border: none;
        height: 3px;
        width: 100%;
        position: absolute;
        top: -2px;
        left: 0;
        -webkit-transition: all .55s ease;
        transition: all .35s ease;
        cursor: pointer;
        &:nth-of-type(2) {
            top: 7px;
        }
        &:nth-of-type(3) {
            top: 16px;
        }
    }
}


/*-----------------res-nav-end-------------------*/
.rightsidebar{
    display: flex;
    align-items: center;
    float: none;
    justify-content: end;
    .right-login{
        .link-menu{
            display: flex;
            align-items: center;
            img{
                padding-right: 10px;
            }
        }
    }
}

.link-menu{
    border-radius: 10px;
    background: #009DA5;
    padding: 16px 34px;
    color: #fff;
    font-family: Lato;
    font-size: 17px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.34px;
    &:hover{
        color: #fff;
        background-color: #2a8e93;
    }
}
.afterlogin {
    display: flex;
    align-items: center;

    .login{
        color: #001919;
        font-family: Lato;
        font-size: 17px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 0.34px;
        display: flex;
        align-items: center;
        svg{
            margin-left: 5px;
        }
    }
}
a.my {
    border-radius: 10px;
    background: #009DA5;
    height: 50px;
    float: left;
    line-height: 50px;
    color: #FFF;
    font-family: Lato;
    font-size: 17px;
    font-style: normal;
    font-weight: 700;
    letter-spacing: 0.34px;
    padding: 0 27px;
    margin-right: 27px;
    &:hover, &:focus{
        color: #fff;

    }
}