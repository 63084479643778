@media only screen and (min-width: 1600px) and (max-width: 1899px) {
  body {
    .about-vision .inner-row .list .left p {
      margin-bottom: 15px;
    }

    .education .list-education {

      margin-top: 50px;
    }

    .education .btn .main-btn {
      width: 360px;
      margin-top: 40px;
    }
  }
}

/*hiral css*/
@media only screen and (min-width: 1350px) and (max-width: 1599px) {
  body {

    /* ====university-search==== */
    .herobanner.university-search {
      .innerbanner {
        h1 {
          font-size: 35px;
        }
      }

      .search-box {
        bottom: -136px;
      }

      padding-top: 50px;
      padding-bottom: 95px;
    }

    .university-search-section {
      padding-top: 115px;

      ul {
        grid-gap: 20px;

        li {
          .title {
            font-size: 19px;
          }

          h3 {
            font-size: 17px;
          }

          .btn-wrap {
            a {
              font-size: 16px;
            }
          }
        }
      }
    }

    // ====form====
    .common-form-page {
      .left-right-wrap {
        height: 100vh;
        grid-template-columns: 40% 60%;
      }

      .left-wrap {
        padding: 40px 40px 60px 40px;

        .profile-slider-wrap {
          width: 430px;

          .content-wrap {
            h2 {
              font-size: 35px;
              padding-bottom: 10px;
            }
          }

          .slick-dots {
            padding-top: 65px;
          }
        }
      }

      .right-wrap {
        h3 {
          font-size: 33px;
        }

        p {
          font-size: 15px;
        }
      }
    }

    /*about page css*/
    .about-hero {
      padding-bottom: 90px !important;
    }

    .about-vision .inner-row .list .left p {
      margin-bottom: 15px;
    }

    .about-vision .inner-row .list .left h2 {
      padding-bottom: 10px;
    }

    .about-vision .inner-row .list {
      gap: 0 40px;
    }

    .our-value {
      padding-bottom: 20px !important;
    }

    /*education css*/
    .education .list-education {

      margin-top: 50px;
    }

    .education .list-education {

      gap: 50px 30px;
    }

    .education h2 {
      font-size: 21px;
    }

    .education .list-education .left h3 {
      font-size: 15px;
      margin-top: 10px;
    }

    .education .list-education .left {
      padding-top: 24px;
    }

    .education .btn .main-btn {
      width: 360px;
      margin-top: 40px;
    }

    footer#footer {
      padding-top: 90px;
    }

    /*country css*/
    .country .innerrow .list-country .left h3 {
      font-size: 15px;
      margin-left: 5px;
    }

    .country .innerrow .list-country .left {

      height: 59px;
      line-height: 59px;
    }

    .country .innerrow .list-country {

      gap: 20px;
    }

    .country .heading {
      margin-bottom: 40px;
    }

    .country .innerrow .btn {
      margin-top: 30px;
    }
  }
}

@media only screen and (min-width: 1280px) and (max-width: 1349px) {
  body {

    /* ====university-search==== */
    .herobanner.university-search {
      .innerbanner {
        h1 {
          font-size: 35px;
        }
      }

      .search-box {
        bottom: -136px;
      }

      padding-top: 50px;
      padding-bottom: 95px;
    }

    .university-search-section {
      padding-top: 115px;

      ul {
        grid-gap: 20px;
        grid-template-columns: repeat(3, 1fr);

        li {
          .title {
            font-size: 19px;
          }

          h3 {
            font-size: 17px;
          }

          .btn-wrap {
            a {
              font-size: 16px;
            }
          }
        }
      }
    }

    // ====form====
    .common-form-page {
      .left-right-wrap {
        grid-template-columns: 45% 55%;
      }

      .left-wrap {
        padding: 40px 40px 60px 40px;

        .profile-slider-wrap {
          .content-wrap {
            h2 {
              font-size: 35px;
              padding-bottom: 10px;
            }

            p {
              font-size: 17px;
              max-width: 350px;
            }
          }

          .slick-dots {
            padding-top: 65px;
          }
        }
      }

      .right-wrap {
        h3 {
          font-size: 33px;
        }

        p {
          font-size: 15px;
        }

        .with-login-btn-wrap {
          grid-gap: 0px 10px;

          button.common {
            font-size: 15px;
            padding: 15px 15px 15px 35px;

            &::before {
              left: 14px;
            }
          }
        }
      }
    }

    /*about page css*/
    .about-hero {
      padding-bottom: 90px !important;
    }

    .about-vision .inner-row .list .left p {
      margin-bottom: 15px;
    }

    .about-vision .inner-row .list .left h2 {
      padding-bottom: 10px;
    }

    .about-vision .inner-row .list {
      gap: 0 40px;
    }

    .our-value {
      padding-bottom: 20px !important;
    }

    .our-value .innerrow .innerlist .left {
      padding: 20px;
    }

    .our-value .innerrow .innerlist .left h3 {
      padding-top: 16px;
      padding-bottom: 12px;
      font-size: 18px;
    }

    /*education css*/
    .education .list-education {

      margin-top: 50px;
    }

    .education .list-education {

      gap: 50px 30px;
    }

    .education h2 {
      font-size: 20px;
    }

    .education .list-education .left h3 {
      font-size: 15px;
      margin-top: 10px;
    }

    .education .list-education .left {
      padding-top: 24px;
    }

    .education .btn .main-btn {
      width: 360px;
      margin-top: 40px;
    }

    /*country css*/
    .country .innerrow .list-country .left h3 {
      font-size: 15px;
      margin-left: 14px;
    }

    .country .innerrow .list-country .left {

      height: 59px;
      line-height: 59px;
    }

    .country .innerrow .list-country {

      gap: 20px;
    }

    .country .heading {
      margin-bottom: 40px;
    }

    .country .innerrow .btn {
      margin-top: 30px;
    }
  }
}

@media only screen and (min-width: 1121px) and (max-width: 1279px) {
  body {

    /* ====university-search==== */
    .herobanner.university-search {
      .innerbanner {
        h1 {
          font-size: 35px;
        }
      }

      .search-box {
        bottom: -136px;
      }

      padding-top: 50px;
      padding-bottom: 95px;
    }

    .university-search-section {
      padding-top: 115px;

      ul {
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 20px;

        li {
          .title {
            font-size: 19px;
          }

          h3 {
            font-size: 17px;
          }

          .btn-wrap {
            a {
              font-size: 16px;
            }
          }
        }
      }
    }

    // ====form====
    .common-form-page {
      .left-right-wrap {
        grid-template-columns: 45% 55%;
      }

      .left-wrap {
        padding: 40px 40px 60px 40px;

        .profile-slider-wrap {
          .content-wrap {
            h2 {
              font-size: 35px;
              padding-bottom: 10px;
            }

            p {
              font-size: 17px;
              max-width: 350px;
            }
          }

          .slick-dots {
            padding-top: 65px;
          }
        }
      }

      .right-wrap {
        h3 {
          font-size: 33px;
        }

        p {
          font-size: 15px;
        }

        .with-login-btn-wrap {
          grid-gap: 0px 10px;

          button.common {
            font-size: 15px;
            padding: 15px 15px 15px 35px;

            &::before {
              left: 14px;
            }
          }
        }
      }
    }

    /*about page css*/
    .about-hero {
      padding-top: 70px !important;
      padding-bottom: 70px !important;
    }

    .about-hero .innerbanner .left h2 {
      margin-bottom: 10px;
    }

    .our-value .innerrow .innerlist {

      gap: 0 20px;
    }

    .our-value .innerrow h2 {
      margin-bottom: 30px;
    }

    .about-vision .inner-row .list .left p {
      margin-bottom: 15px;
    }

    .about-vision .inner-row .list .left h2 {
      padding-bottom: 10px;
    }

    .about-vision .inner-row .list {
      gap: 0 40px;
    }

    .our-value {
      padding-bottom: 20px !important;
    }

    .our-value .innerrow .innerlist .left {
      padding: 20px;
    }

    .our-value .innerrow .innerlist .left h3 {
      padding-top: 16px;
      padding-bottom: 12px;
      font-size: 18px;
    }

    /*education css*/
    .education .list-education {

      margin-top: 50px;
    }

    .education .list-education {

      gap: 40px 30px;
    }

    .education h2 {
      font-size: 20px;
    }

    .education .list-education .left h3 {
      font-size: 14px;
      margin-top: 10px;
    }

    .education .list-education .left {
      padding-top: 24px;
    }

    .education .btn .main-btn {
      width: 300px;
      margin-top: 40px;
    }

    /*country css*/
    .country .innerrow .list-country .left h3 {
      font-size: 14px;
      margin-left: 0px;
    }

    .country .innerrow .list-country .left {

      height: 59px;
      line-height: 59px;
    }

    .country .innerrow .list-country {

      gap: 20px;
    }

    .country .heading {
      margin-bottom: 50px;
    }

    .country .innerrow .btn {
      margin-top: 30px;
    }

    .country .innerrow .list-country {
      grid-template-columns: repeat(3, 1fr);
    }
  }
}

@media only screen and (min-width: 992px) and (max-width: 1120px) {
  body {

    /* ====university-search==== */
    .herobanner.university-search {
      .innerbanner {
        h1 {
          font-size: 35px;
        }
      }

      .search-box {
        bottom: -136px;
      }

      padding-top: 50px;
      padding-bottom: 95px;
    }

    .university-search-section {
      padding-top: 115px;

      ul {
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 20px;

        li {
          .title {
            font-size: 19px;
          }

          h3 {
            font-size: 17px;
          }

          .btn-wrap {
            a {
              font-size: 16px;
            }
          }
        }
      }
    }

    .university-search-section.chance {
      ul {
        li {
          h3 {
            font-size: 14px;
          }
        }
      }
    }

    // ====form====
    .common-form-page {
      .left-right-wrap {
        grid-template-columns: 45% 55%;
      }

      .left-wrap {
        padding: 40px 40px 60px 40px;

        .profile-slider-wrap {
          .content-wrap {
            h2 {
              font-size: 35px;
              padding-bottom: 10px;
            }

            p {
              font-size: 17px;
              max-width: 350px;
            }
          }

          .slick-dots {
            padding-top: 65px;
          }
        }
      }

      .right-wrap {
        h3 {
          font-size: 33px;
        }

        p {
          font-size: 15px;
        }

        .with-login-btn-wrap {
          grid-gap: 0px 10px;

          button.common {
            font-size: 13px;
            padding: 15px 15px 15px 45px;

            &::before {
              left: 14px;
            }
          }
        }
      }
    }

    // ====dashboard-page==
    .dashboard-section {
      padding-top: 65px;
      padding-bottom: 65px;
      margin-bottom: 60px;
    }

    /*about page css*/
    .about-hero {
      padding-top: 70px !important;
      padding-bottom: 70px !important;
    }

    .about-hero .innerbanner .left h2 {
      margin-bottom: 10px;
    }

    .about-hero .innerbanner .left {

      padding-top: 20px;
    }

    .our-value .innerrow .innerlist {

      gap: 0 20px;
    }

    .our-value .innerrow h2 {
      margin-bottom: 30px;
    }

    .about-vision .inner-row .list .left p {
      margin-bottom: 10px;
    }

    .about-vision .inner-row .list .left h2 {
      padding-bottom: 0px;
    }

    .about-vision .inner-row .list {
      gap: 0 40px;
    }

    .our-value {
      padding-bottom: 20px !important;
    }

    .our-value .innerrow .innerlist .left {
      padding: 20px;
    }

    .our-value .innerrow .innerlist .left h3 {
      padding-top: 16px;
      padding-bottom: 12px;
      font-size: 18px;
    }

    /*education css*/
    .education .list-education {

      margin-top: 30px;
    }

    .education .list-education {

      gap: 40px 30px;
    }

    .education h2 {
      font-size: 18px;
    }

    .education .list-education .left h3 {
      font-size: 14px;
      margin-top: 10px;
    }

    .education .list-education .left {
      padding-top: 24px;
    }

    .education .btn .main-btn {
      width: 300px;
      margin-top: 40px;
    }

    /*country css*/
    .country .innerrow .list-country .left h3 {
      font-size: 13px;
      margin-left: 0px;
    }

    .country .innerrow .list-country .left {

      height: 50px;
      line-height: 50px;
    }

    .country .innerrow .list-country {

      gap: 20px;
    }

    .country .heading {
      margin-bottom: 50px;
    }

    .country .innerrow .btn {
      margin-top: 30px;
    }

    .country .innerrow .list-country {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  body {

    /* ====university-search==== */
    .herobanner.university-search {
      .innerbanner {
        h1 {
          font-size: 32px;
        }
      }
    }

    .herobanner.university-search .search-box {
      bottom: -286px;
    }

    .university-search-section {
      padding-top: 205px;

      ul {
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 20px;

        li {
          .title {
            font-size: 20px;
          }

          h3 {
            font-size: 17px;
          }

          .btn-wrap {
            a {
              font-size: 16px;
            }
          }
        }
      }
    }

    // ====form====
    .common-form-page {
      .left-right-wrap {
        grid-template-columns: 1fr;
      }

      .left-wrap {
        padding: 20px 30px 20px 20px;
        height: inherit;
        display: block;

        .bottom-wrap {
          display: none;
        }

        .logo-wrap {
          max-width: 130px;
          height: auto;
        }
      }

      .right-wrap {
        padding: 40px 15px;

        h3 {
          font-size: 25px;
          padding-bottom: 15px;
        }

        p {
          font-size: 15px;
        }

        .checkbox-text-wrap {
          span {
            font-size: 12px;
          }
        }

        .checkbox-forgot {
          .forgot-wrap {
            a {
              font-size: 13px;
            }
          }
        }

        .btn-wrap {
          input.btn {
            font-size: 16px;
            padding: 13px 25px;
          }
        }

        .with-login-btn-wrap {
          grid-template-columns: repeat(1, 1fr);
          grid-gap: 20px 25px;
        }
      }
    }

    html.form-height {
      height: inherit;

      body {
        height: inherit;
      }

      .common-form-page {
        height: inherit;

        >.wrap {
          height: inherit;
        }
      }

      .left-right-wrap {
        height: inherit;
      }
    }

    // ====dashboard-page==
    .dashboard-section {
      padding-top: 65px;
      padding-bottom: 65px;
      margin-bottom: 60px;
    }

    /*about page css*/
    .about-hero {
      padding-top: 70px !important;
      padding-bottom: 70px !important;
    }

    .about-hero .innerbanner .left h2 {
      margin-bottom: 10px;
    }

    .about-hero .innerbanner .left {

      padding-top: 20px;
    }

    .our-value .innerrow .innerlist {
      grid-template-columns: repeat(2, 1fr);
      gap: 20px;
    }

    .our-value .innerrow h2 {
      margin-bottom: 30px;
    }

    .about-vision .inner-row .list .left p {
      margin-bottom: 10px;
    }

    .about-vision .inner-row .list .left h2 {
      padding-bottom: 0px;
    }

    .about-vision .inner-row .list .left.pdr {
      padding-left: 0;
    }

    .about-vision .inner-row .list {
      gap: 0 20px;
    }

    .our-value {
      padding-bottom: 20px !important;
    }

    .our-value .innerrow .innerlist .left {
      padding: 20px;
    }

    .our-value .innerrow .innerlist .left h3 {
      padding-top: 16px;
      padding-bottom: 12px;
      font-size: 18px;
    }

    /*education css*/
    .education .list-education {

      margin-top: 30px;
    }

    .education .list-education {

      gap: 40px 30px;
    }

    .education h2 {
      font-size: 18px;
    }

    .education .list-education .left h3 {
      font-size: 14px;
      margin-top: 10px;
    }

    .education .list-education .left {
      padding-top: 24px;
    }

    .education .btn .main-btn {
      width: 250px;
      margin-top: 40px;
    }

    .education .list-education {

      grid-template-columns: repeat(3, 1fr);

    }

    /*country css*/
    .country .innerrow .list-country .left h3 {
      font-size: 13px;
      margin-left: 14px;
    }

    .country .innerrow .list-country .left {

      height: 50px;
      line-height: 50px;
    }

    .country .innerrow .list-country {

      gap: 20px;
    }

    .country .heading {
      margin-bottom: 60px;
    }

    .country .innerrow .btn {
      margin-top: 30px;
    }

    .country .innerrow .list-country {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  .for-phone{
    display: block;
  }
}

@media (max-width: 767px) {
  .for-phone{
    display: block;
  }
  body h3 {
    font-size: 13px;
  }

  body {

    /* ====university-search==== */
    .herobanner.university-search .search-box {
      bottom: -16px;
    }

    .about-hero {

      padding-top: 50px !important;
    }

    .herobanner.university-search {
      padding-top: 50px;
      padding-bottom: 71px;

      .innerbanner {
        h1 {
          font-size: 25px;
          padding-bottom: 10px;
        }
      }
    }

    .university-search-section {
      padding-top: 55px;
    }

    .university-search-section {
      ul {
        grid-gap: 30px;
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 20px;

        li {
          .title {
            font-size: 19px;
            font-size: 18px;
          }

          h3 {
            font-size: 16px;
            font-size: 16px;
          }

          .btn-wrap {
            a {
              font-size: 16px;
              font-size: 16px;
            }
          }
        }
      }
    }

    // ====form====
    .common-form-page {
      .left-right-wrap {
        grid-template-columns: 1fr;
      }

      .left-wrap {
        padding: 20px 30px 20px 20px;
        height: inherit;
        display: block;

        .bottom-wrap {
          display: none;
        }

        .logo-wrap {
          max-width: 130px;
          height: auto;
        }
      }

      .right-wrap {
        padding: 40px 15px;

        h3 {
          font-size: 25px;
          padding-bottom: 15px;
        }

        p {
          font-size: 15px;
        }

        .checkbox-text-wrap {
          span {
            font-size: 12px;
          }
        }

        .checkbox-forgot {
          .forgot-wrap {
            a {
              font-size: 13px;
            }
          }
        }

        .btn-wrap {
          input.btn {
            font-size: 16px;
            padding: 13px 25px;
          }
        }

        .with-login-btn-wrap {
          grid-template-columns: repeat(1, 1fr);
          grid-gap: 20px 25px;
        }
      }
    }

    html.form-height {
      height: inherit;

      body {
        height: inherit;
      }

      .common-form-page {
        height: inherit;

        >.wrap {
          height: inherit;
        }
      }

      .left-right-wrap {
        height: inherit;
      }
    }

    // ====dashboard-page===

    .dashboard-section {
      padding-top: 75px;
      padding-bottom: 65px;
      margin-bottom: 60px;

      ul.title {
        padding: 15px 15px;
        grid-template-columns: repeat(4, 1fr);

        li {
          font-size: 13px;
        }
      }

      .data-wrap {
        ul.data {
          padding: 11px 15px;
          grid-template-columns: repeat(4, 1fr);

          li {
            font-size: 13px;
          }

          li.status {
            font-size: 12px;
            padding: 5px 8px;
          }
        }
      }
    }

    /*about page css*/
    .about-hero {
      padding-bottom: 70px !important;
    }

    .about-hero .innerbanner .left h2 {
      margin-bottom: 10px;
    }

    .about-hero .innerbanner .left {

      padding-top: 20px;
    }

    .our-value .innerrow .innerlist {
      grid-template-columns: repeat(2, 1fr);
      gap: 20px;
    }

    .our-value .innerrow h2 {
      margin-bottom: 30px;
    }

    .about-vision .inner-row .list .left p {
      margin-bottom: 10px;
    }

    .about-vision .inner-row .list .left h2 {
      padding-bottom: 0px;
    }

    .about-vision .inner-row .list .left.pdr {
      padding-left: 0;
    }

    .about-vision .inner-row .list {
      gap: 0 20px;
    }

    .our-value {
      padding-bottom: 20px !important;
    }

    .our-value .innerrow .innerlist .left {
      padding: 20px;
    }

    .our-value .innerrow .innerlist .left h3 {
      padding-top: 16px;
      padding-bottom: 12px;
      font-size: 18px;
    }

    .about-hero {
      padding-bottom: 50px !important;
    }

    /*education css*/
    .education .list-education {

      margin-top: 30px;
    }

    .education .list-education {

      gap: 40px 30px;
    }

    .education h2 {
      font-size: 18px;
    }

    .education .list-education .left h3 {
      font-size: 14px;
      margin-top: 10px;
    }

    .education .list-education .left {
      padding-top: 24px;
    }

    .education .btn .main-btn {
      width: 250px;
      margin-top: 40px;
    }

    .education .list-education {

      grid-template-columns: repeat(2, 1fr);

    }

    /*country css*/
    .country .innerrow .list-country .left h3 {
      font-size: 13px;
      margin-left: 14px;
    }

    .country .innerrow .list-country .left {

      height: 50px;
      line-height: 50px;
    }

    .country .innerrow .list-country {

      gap: 20px;
    }

    .country .heading {
      margin-bottom: 50px;
    }

    .country .innerrow .btn {
      margin-top: 30px;
    }

    .country .innerrow .list-country {
      grid-template-columns: repeat(1, 1fr);
    }
  }

  body .dashboard-section .container {
    overflow-y: hidden;
    overflow-x: auto;
  }

  body .common-form-page .left-wrap .copyright-wrap {
    display: none;
  }
}

@media only screen and (min-width: 580px) and (max-width: 767px) {
  body {
    .university-search-section {
      ul {
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 10px;
      }
    }
  }
}

/*hiral css end*/
@media only screen and (min-width: 1600px) and (max-width: 1899px) {
  body {
    .herobanner .innerbanner .left h1 {
      max-width: 509px;
    }

    h1 {
      font-size: 50px;
    }

    .application:after {
      width: 250.5px;
    }

    .herobanner {
      padding: 60px 0 157px 0;
    }

    .herobanner .search-box {
      bottom: -162px;
    }

    .search-box {
      padding: 21px 23px;
    }

    .search-box .col label {
      font-size: 19px;
    }

    .search-box .col select {
      font-size: 17px;
    }

    .form-btn {
      font-size: 16px;
      padding: 20px 38px;
    }

    h2 {
      font-size: 39px;
    }

    .par-bg:after {
      left: -6px;
      width: 165px;
    }

    .partners .inner-roe .partners-logo {
      margin-top: 39px;
    }

    .pd100 {
      padding: 100px 0;
    }

    .profile:after {
      width: 331px;
    }

    .about .inner-row .right {
      padding-left: 80px;
    }

    .about .inner-row .right p {
      padding-top: 18px;
      padding-bottom: 35px;
    }

    .main-btn {
      font-size: 16px;
      padding: 17px 40px;
    }

    .courseBuddy:after {
      left: -5px;
      width: 246px;
    }

    .benefits .inner-row .top {
      padding-bottom: 37px;
    }

    .benefits .inner-row .benefits-list .left {
      padding-top: 33px;
    }

    .h3 {
      font-size: 34px;
    }

    .one-profile .inner-row .name {
      margin-top: 24px;
      font-size: 19px;
    }

    .mission:after {
      left: -6px;
      width: 125px;
    }

    .vision .inner-row .list:first-child {
      padding-bottom: 90px;
    }

    .mission-2:after {
      width: 156px;
    }

    .vision .inner-row .list:last-child .left:first-child {
      padding-left: 20px;
    }

    .simplify .top h2 span {
      padding-left: 292px;
    }

    .simplify .top h2:before {
      left: 228px;
      width: 559px;
    }

    .simplify .list-type {
      margin-top: 34px;
    }

    .simplify .list-type .list h3 {
      font-size: 22px;
    }

    .simplify .list-type .list ul {
      margin-top: 16px;
      margin-bottom: 70px;
    }

    .simplify .list-type .list ul li {
      font-size: 15px;
    }

    .read-more-btn {
      font-size: 16px;
    }

    .cta {
      padding-top: 180px !important;
    }

    .cta .inner-cta h2 {
      max-width: 524px;
    }

    .cta .inner-cta {
      padding: 70px;
    }

    .word:after {
      bottom: -8px;
      left: -5px;
      width: 364px;
    }

    .cta .inner-cta .ctaimage {
      right: 90px;
      top: -129px;
      width: 38%;
    }

    #footer .foter-top {
      padding-bottom: 55px;
    }

    a.my {
      font-size: 16px;
    }

    .afterlogin .login {
      font-size: 16px;
    }

    /*profile css*/
    .hero-profile .inner .names h2 {
      font-size: 27px;
    }

    .hero-profile .inner .names h3 {
      margin-top: 6px;
      font-size: 15px;
    }

    .hero-profile {
      min-height: 200px;
    }

    .tabs ul#tabs-nav li a {
      font-size: 15px;
    }

    .tabs ul#tabs-nav li {
      padding: 8px 45px;
    }

    #tabs-content {
      margin-top: 40px;
    }

    #tabs-content .tab-content .innertab .cols .heading {
      padding: 20px 25px;
    }

    .form label {
      font-size: 15px;
    }

    .form input::placeholder {
      font-size: 15px;
    }

    .btns .save {
      min-width: 240px;
      font-size: 16px;
    }

    .pdrl {
      padding: 22px 25px;
    }
  }
}

@media only screen and (min-width: 1350px) and (max-width: 1599px) {
  body {
    .container {
      width: 1200px;
    }

    .fixed .link-menu {
      padding: 11px 25px;
    }

    .header .header-right .nav-main ul.menu li {
      margin: 0 30px;
    }

    .header .header-right .nav-main ul.menu li.menu-item-has-children {
      background-position: right 4px center;
    }

    .herobanner .innerbanner .left h1 {
      max-width: 479px;
    }

    .link-menu {
      padding: 14px 25px;
      font-size: 16px;
    }

    .header .header-right .nav-main ul.menu li a {
      font-size: 16px;
    }

    .header .header-right .nav-main ul.menu li ul.sub-menu li a {
      font-size: 14px;
    }

    section {
      margin-top: 88px;
    }

    #header {
      padding: 12px 0;
    }

    h1 {
      font-size: 43px;
    }

    .herobanner .innerbanner .left p {
      max-width: 538px;
    }

    .herobanner .innerbanner .left p a {
      font-size: 17px;
    }

    p {
      font-size: 17px;
    }

    .application:after {
      width: 218px;
      bottom: -13px;
    }

    .herobanner .innerbanner .left p {
      padding-top: 14px;
    }

    .herobanner .innerbanner .right img {
      width: 72%;
    }

    .herobanner {
      padding: 50px 0 117px 0;
    }

    .herobanner .search-box {
      bottom: -142px;
    }

    .search-box {
      padding: 21px 23px;
    }

    .search-box .col label {
      font-size: 17px;
      margin-bottom: 0;
    }

    .search-box .col select {
      font-size: 15px;
    }

    .form-btn {
      font-size: 16px;
      padding: 20px 38px;
    }

    h2 {
      font-size: 36px;
    }

    .about .inner-row .right h2 {
      width: 100%;
    }

    .par-bg:after {
      left: -6px;
      width: 150px;
    }

    .readmore {
      font-size: 16px;
    }

    .partners .inner-roe .partners-logo {
      margin-top: 32px;
    }

    .pd100 {
      padding: 90px 0;
    }

    .profile:after {
      width: 301px;
      bottom: -14px;
    }

    .about .inner-row .right {
      padding-left: 80px;
    }

    .about .inner-row .right p {
      padding-top: 18px;
      padding-bottom: 25px;
    }

    .main-btn {
      font-size: 15px;
      padding: 17px 34px;
    }

    .courseBuddy:after {
      left: -5px;
      width: 226px;
      background-size: contain;
    }

    .benefits .inner-row .slick-slide {
      margin: 0 10px;
    }

    .benefits .inner-row .benefits-list .left p {
      font-size: 15px;
    }

    .benefits .inner-row .benefits-list .left h3 {
      margin-top: 9px;
      margin-bottom: 9px;
    }

    .benefits .inner-row .benefits-list .left h3 {
      font-size: 17px;
    }

    .benefits .inner-row .top {
      padding-bottom: 31px;
    }

    .benefits .inner-row .benefits-list .left {
      padding-top: 33px;
    }

    .h3 {
      font-size: 31px;
    }

    .one-profile .inner-row .name {
      margin-top: 17px;
      font-size: 17px;
    }

    .one-profile .inner-row .position {
      font-size: 15px;
    }

    .mission:after {
      left: -6px;
      width: 117px;
      bottom: -5px;
    }

    .vision .inner-row .list:first-child {
      padding-bottom: 80px;
    }

    .mission-2:after {
      width: 145px;
      bottom: -5px;
    }

    .vision .inner-row .list:last-child .left:first-child {
      padding-left: 20px;
    }

    .simplify .top h2 span {
      padding-left: 272px;
    }

    .simplify .top h2:before {
      left: 208px;
      width: 519px;
    }

    .simplify .list-type {
      margin-top: 34px;
    }

    .simplify .list-type .list h3 {
      font-size: 20px;
    }

    .simplify .list-type .list ul {
      margin-top: 13px;
      margin-bottom: 50px;
    }

    .simplify .list-type .list {
      padding: 23px;
    }

    .simplify .list-type .list ul li {
      font-size: 14px;
    }

    .read-more-btn {
      font-size: 14px;
    }

    .cta {
      padding-top: 160px !important;
    }

    .cta .inner-cta h2 {
      max-width: 494px;
    }

    .cta .inner-cta {
      padding: 70px;
    }

    .word:after {
      bottom: -8px;
      left: -5px;
      width: 335px;
    }

    .cta .inner-cta .ctaimage {
      right: 63px;
      top: -114px;
      width: 42%;
    }

    #footer .foter-top {
      padding-bottom: 49px;
    }

    #footer .foter-top .righrbar ul li {
      margin-left: 20px;
    }

    a.my {
      font-size: 16px;
      padding: 0 20px;
      margin-right: 18px;
    }

    .afterlogin .login {
      font-size: 16px;
    }

    /*profile css*/
    .hero-profile .inner .names h2 {
      font-size: 27px;
    }

    .hero-profile .inner .names h3 {
      margin-top: 6px;
      font-size: 15px;
    }

    .hero-profile {
      min-height: 200px;
    }

    .tabs ul#tabs-nav li a {
      font-size: 15px;
    }

    .tabs ul#tabs-nav li {
      padding: 8px 45px;
    }

    #tabs-content {
      margin-top: 40px;
    }

    #tabs-content .tab-content .innertab .cols .heading {
      padding: 20px 25px;
    }

    .form label {
      font-size: 15px;
    }

    .form input::placeholder {
      font-size: 15px;
    }

    .btns .save {
      min-width: 240px;
      font-size: 16px;
    }

    .pdrl {
      padding: 22px 25px;
    }

    #tabs-content .tab-content .innertab .cols .filed {
      gap: 0 25px;
    }

    body.user-profile footer#footer {
      padding-top: 90px !important;
    }

    .hero-profile .inner .notifition {
      top: 43px;
    }
  }
}

@media only screen and (min-width: 1280px) and (max-width: 1349px) {
  body {
    .container {
      width: 1200px;
    }

    .fixed .link-menu {
      padding: 11px 25px;
    }

    .header .header-right .nav-main ul.menu li {
      margin: 0 30px;
    }

    .header .header-right .nav-main ul.menu li.menu-item-has-children {
      background-position: right 4px center;
    }

    .herobanner .innerbanner .left h1 {
      max-width: 479px;
    }

    .link-menu {
      padding: 14px 25px;
      font-size: 16px;
    }

    .header .header-right .nav-main ul.menu li a {
      font-size: 16px;
    }

    .header .header-right .nav-main ul.menu li ul.sub-menu li a {
      font-size: 14px;
    }

    section {
      margin-top: 88px;
    }

    #header {
      padding: 12px 0;
    }

    h1 {
      font-size: 43px;
    }

    .herobanner .innerbanner .left p {
      max-width: 538px;
    }

    .herobanner .innerbanner .left p a {
      font-size: 17px;
    }

    p {
      font-size: 17px;
    }

    .application:after {
      width: 218px;
    }

    .herobanner .innerbanner .left p {
      padding-top: 14px;
    }

    .herobanner .innerbanner .right img {
      width: 85%;
    }

    .herobanner {
      padding: 90px 0 187px 0;
    }

    .herobanner .search-box {
      bottom: -192px;
    }

    .search-box {
      padding: 21px 23px;
    }

    .search-box .col label {
      font-size: 17px;
      margin-bottom: 0;
    }

    .search-box .col select {
      font-size: 15px;
    }

    .form-btn {
      font-size: 16px;
      padding: 20px 38px;
    }

    h2 {
      font-size: 36px;
    }

    .about .inner-row .right h2 {
      width: 100%;
    }

    .par-bg:after {
      left: -6px;
      width: 150px;
    }

    .readmore {
      font-size: 16px;
    }

    .partners .inner-roe .partners-logo {
      margin-top: 32px;
    }

    .pd100 {
      padding: 90px 0;
    }

    .profile:after {
      width: 301px;
      bottom: -14px;
    }

    .about .inner-row .right {
      padding-left: 80px;
    }

    .about .inner-row .right p {
      padding-top: 18px;
      padding-bottom: 25px;
    }

    .main-btn {
      font-size: 15px;
      padding: 17px 34px;
    }

    .courseBuddy:after {
      left: -5px;
      width: 226px;
      background-size: contain;
    }

    .benefits .inner-row .slick-slide {
      margin: 0 10px;
    }

    .benefits .inner-row .benefits-list .left p {
      font-size: 15px;
    }

    .benefits .inner-row .benefits-list .left h3 {
      margin-top: 9px;
      margin-bottom: 9px;
    }

    .benefits .inner-row .benefits-list .left h3 {
      font-size: 17px;
    }

    .benefits .inner-row .top {
      padding-bottom: 31px;
    }

    .benefits .inner-row .benefits-list .left {
      padding-top: 33px;
    }

    .h3 {
      font-size: 31px;
    }

    .one-profile .inner-row .name {
      margin-top: 17px;
      font-size: 17px;
    }

    .one-profile .inner-row .position {
      font-size: 15px;
    }

    .mission:after {
      left: -6px;
      width: 117px;
      bottom: -5px;
    }

    .vision .inner-row .list:first-child {
      padding-bottom: 80px;
    }

    .mission-2:after {
      width: 145px;
      bottom: -5px;
    }

    .vision .inner-row .list:last-child .left:first-child {
      padding-left: 20px;
    }

    .simplify .top h2 span {
      padding-left: 272px;
    }

    .simplify .top h2:before {
      left: 208px;
      width: 519px;
    }

    .simplify .list-type {
      margin-top: 34px;
    }

    .simplify .list-type .list h3 {
      font-size: 20px;
    }

    .simplify .list-type .list ul {
      margin-top: 13px;
      margin-bottom: 50px;
    }

    .simplify .list-type .list {
      padding: 23px;
    }

    .simplify .list-type .list ul li {
      font-size: 14px;
    }

    .read-more-btn {
      font-size: 14px;
    }

    .cta {
      padding-top: 160px !important;
    }

    .cta .inner-cta h2 {
      max-width: 494px;
    }

    .cta .inner-cta {
      padding: 70px;
    }

    .word:after {
      bottom: -8px;
      left: -5px;
      width: 335px;
    }

    .cta .inner-cta .ctaimage {
      right: 63px;
      top: -114px;
      width: 42%;
    }

    #footer .foter-top {
      padding-bottom: 49px;
    }

    #footer .foter-top .righrbar ul li {
      margin-left: 20px;
    }

    a.my {
      font-size: 15px;
      padding: 0 20px;
      margin-right: 18px;
    }

    .afterlogin .login {
      font-size: 15px;
    }

    /*profile css*/
    .hero-profile .inner .names h2 {
      font-size: 24px;
    }

    .hero-profile .inner .names h3 {
      margin-top: 6px;
      font-size: 15px;
    }

    .hero-profile .inner .names {
      top: 31px;
    }

    .hero-profile {
      min-height: 170px;
    }

    #tabs-content .tab-content .innertab .cols .heading h3 {
      font-size: 18px;
    }

    .form select {
      background-position: right 16px center;
    }

    .form input,
    .form select {
      padding: 0 16px;
    }

    .tabs ul#tabs-nav li a {
      font-size: 15px;
    }

    .tabs ul#tabs-nav li {
      padding: 8px 45px;
    }

    .hero-profile .inner .notifition {
      top: 38px;
    }

    #tabs-content {
      margin-top: 40px;
    }

    #tabs-content .tab-content .innertab .cols .heading {
      padding: 16px 25px;
    }

    .form label {
      font-size: 14px;
    }

    .form input::placeholder {
      font-size: 14px;
    }

    .btns .save {
      min-width: 240px;
      font-size: 16px;
    }

    .pdrl {
      padding: 16px 25px;
    }

    #tabs-content .tab-content .innertab .cols .filed {
      gap: 0 25px;
    }

    body.user-profile footer#footer {
      padding-top: 90px !important;
    }

    .hero-profile .inner .profile-section .imageprofile,
    .hero-profile .inner .profile-section .imageprofile:after {
      width: 130px;
      height: 130px;
    }

    .hero-profile .inner {
      top: 31px;
    }

    #tabs-content .tab-content .innertab .cols.third-col .fild {
      margin-bottom: 17px;
    }
  }
}

@media only screen and (min-width: 1121px) and (max-width: 1279px) {
  body {
    .container {
      width: 1050px;
    }

    .fixed .link-menu {
      padding: 11px 25px;
    }

    .header .header-main .logo {
      width: 167px;
    }

    .header .header-right .nav-main ul.menu li {
      padding: 0 30px;
    }

    .header .header-right .nav-main ul.menu li.menu-item-has-children {
      background-position: right 4px center;
    }

    .header .header-main {
      grid-template-columns: auto auto auto;
    }

    .herobanner .innerbanner .left h1 {
      max-width: 379px;
    }

    .link-menu {
      padding: 14px 25px;
      font-size: 16px;
    }

    .header .header-right .nav-main ul.menu li a {
      font-size: 16px;
    }

    .header .header-right .nav-main ul.menu li ul.sub-menu li a {
      font-size: 14px;
    }

    section {
      margin-top: 79px;
    }

    #header {
      padding: 12px 0;
    }

    h1 {
      font-size: 35px;
    }

    .herobanner .innerbanner .left p {
      max-width: 538px;
    }

    .herobanner .innerbanner .left p a {
      font-size: 17px;
    }

    p {
      font-size: 16px;
    }

    .application:after {
      width: 179px;
      bottom: -16px;

      background-size: contain;
    }

    .herobanner .innerbanner .left p {
      padding-top: 14px;
    }

    .herobanner .innerbanner .right img {
      width: 65%;
    }

    .herobanner {
      padding: 60px 0 157px 0;
    }

    .herobanner .search-box {
      bottom: -162px;
    }

    .search-box {
      padding: 21px 23px;
    }

    .search-box .col label {
      font-size: 16px;
      margin-bottom: 0;
    }

    .search-box .col select {
      font-size: 14px;
    }

    .form-btn {
      font-size: 16px;
      padding: 20px 38px;
    }

    h2 {
      font-size: 29px;
    }

    .about .inner-row .right h2 {
      width: 100%;
    }

    .par-bg:after {
      left: -6px;
      width: 123px;
      background-size: contain;
    }

    .readmore {
      font-size: 16px;
    }

    .partners .inner-roe .partners-logo {
      margin-top: 32px;
    }

    .pd100 {
      padding: 70px 0;
    }

    .profile:after {
      width: 241px;
      bottom: -11px;
      background-size: contain;
    }

    .about .inner-row .right {
      padding-left: 80px;
    }

    .about .inner-row .right p {
      padding-top: 18px;
      padding-bottom: 25px;
    }

    .main-btn {
      font-size: 14px;
      padding: 14px 34px;
    }

    .cta .inner-cta a {
      padding: 14px 23px;
    }

    .benefits .inner-row .top p {
      padding-top: 13px;
    }

    .courseBuddy:after {
      left: -5px;
      width: 188px;
      background-size: contain;
    }

    .benefits .inner-row .slick-slide {
      margin: 0 10px;
    }

    .benefits .inner-row .benefits-list .left p {
      font-size: 14px;
    }

    .benefits .inner-row .benefits-list .left h3 {
      margin-top: 9px;
      margin-bottom: 9px;
    }

    .benefits .inner-row .benefits-list .left h3 {
      font-size: 16px;
    }

    .benefits .inner-row .top {
      padding-bottom: 31px;
    }

    .benefits .inner-row .benefits-list .left {
      padding-top: 33px;
    }

    .h3 {
      font-size: 27px;
      line-height: 120%;
    }

    .one-profile .inner-row .name {
      margin-top: 17px;
      font-size: 17px;
    }

    .one-profile .inner-row .position {
      font-size: 15px;
    }

    .mission:after {
      left: -6px;
      width: 95px;
      bottom: -5px;
      background-size: contain;
    }

    .vision .inner-row .list .left p {
      width: 100%;
    }

    .vision .inner-row .list:first-child {
      padding-bottom: 50px;
    }

    p {
      line-height: 160%;
    }

    .mission-2:after {
      width: 114px;
      bottom: -5px;
      background-size: contain;
      left: -5px;
    }

    .vision .inner-row .list:last-child .left:first-child {
      padding-left: 20px;
    }

    .simplify .top h2 span {
      padding-left: 222px;
    }

    .simplify .top h2:before {
      left: 167px;
      width: 422px;
      top: -17px;
    }

    .simplify .list-type {
      margin-top: 24px;
      gap: 0 20px;
    }

    .simplify .list-type .list h3 {
      font-size: 18px;
    }

    .simplify .list-type .list ul {
      margin-top: 13px;
      margin-bottom: 50px;
    }

    .simplify .list-type .list {
      padding: 23px;
    }

    .simplify .list-type .list ul li {
      font-size: 14px;
    }

    .read-more-btn {
      font-size: 14px;
    }

    .cta {
      padding-top: 100px !important;
    }

    .cta .inner-cta h2 {
      max-width: 384px;
    }

    .cta .inner-cta {
      padding: 50px;
    }

    .word:after {
      bottom: -8px;
      left: -5px;
      width: 275px;
      background-size: contain;
    }

    .cta .inner-cta p {
      max-width: 557px;
    }

    .cta .inner-cta .ctaimage {
      right: 63px;
      top: -52px;
      width: 35%;
    }

    #footer .foter-top {
      padding-bottom: 39px;
    }

    #footer .foter-top .righrbar ul li {
      margin-left: 20px;
    }

    a.my {
      font-size: 14px;
      padding: 0 20px;
      height: 45px;
      line-height: 45px;
      margin-right: 18px;
    }

    .afterlogin .login {
      font-size: 14px;
    }

    /*profile css*/
    .hero-profile .inner .names h2 {
      font-size: 24px;
    }

    .hero-profile .inner .names h3 {
      margin-top: 6px;
      font-size: 15px;
    }

    .hero-profile .inner .names {
      top: 31px;
    }

    .hero-profile {
      min-height: 150px;
    }

    #tabs-content .tab-content .innertab .cols .heading h3 {
      font-size: 18px;
    }

    .form select {
      background-position: right 16px center;
    }

    .form input,
    .form select {
      padding: 0 16px;
    }

    .tabs ul#tabs-nav li a {
      font-size: 15px;
    }

    .tabs ul#tabs-nav li {
      padding: 8px 36px;
    }

    .hero-profile .inner .notifition {
      top: 38px;
    }

    #tabs-content {
      margin-top: 40px;
    }

    #tabs-content .tab-content .innertab .cols .heading {
      padding: 16px 25px;
    }

    .form label {
      font-size: 14px;
    }

    .form input::placeholder {
      font-size: 14px;
    }

    .btns .save {
      min-width: 180px;
      font-size: 15px;
    }

    .pdrl {
      padding: 16px 25px;
    }

    #tabs-content .tab-content .innertab .cols .filed {
      gap: 0 25px;
    }

    body.user-profile footer#footer {
      padding-top: 70px !important;
    }

    .hero-profile .inner .profile-section .imageprofile,
    .hero-profile .inner .profile-section .imageprofile:after {
      width: 120px;
      height: 120px;
    }

    .hero-profile .inner {
      top: 22px;
    }

    #tabs-content .tab-content .innertab .cols.third-col .fild {
      margin-bottom: 17px;
    }

    #tabs-content .tab-content .innertab .cols.second-col {
      margin-top: 25px;
    }

    .herobanner .innerbanner .left {

      padding-top: 40px;
    }
  }
}

@media only screen and (min-width: 992px) and (max-width: 1120px) {
  body {
    .container {
      width: 900px;
    }

    .search-box .col {
      padding-left: 25px;
      padding-right: 25px;
    }

    .fixed .link-menu {
      padding: 11px 25px;
    }

    .header .header-main .logo {
      width: 167px;
    }

    .header .header-right .nav-main ul.menu li {
      padding: 0 30px;
    }

    .header .header-right .nav-main ul.menu li.menu-item-has-children {
      background-position: right 4px center;
    }

    .header .header-main {
      grid-template-columns: auto auto auto;
    }

    .herobanner .innerbanner .left h1 {
      max-width: 379px;
    }

    .link-menu {
      padding: 14px 25px;
      font-size: 16px;
    }

    .header .header-right .nav-main ul.menu li a {
      font-size: 15px;
    }

    .header .header-right .nav-main ul.menu li ul.sub-menu li a {
      font-size: 14px;
    }

    section {
      margin-top: 79px;
    }

    #header {
      padding: 12px 0;
    }

    h1 {
      font-size: 35px;
    }

    .herobanner .innerbanner .left p {
      max-width: 538px;
    }

    .herobanner .innerbanner .left p a {
      font-size: 17px;
    }

    p {
      font-size: 16px;
    }

    .application:after {
      width: 179px;
      bottom: -16px;

      background-size: contain;
    }

    .herobanner .innerbanner .left p {
      padding-top: 14px;
    }

    .herobanner .innerbanner .left {

      padding-top: 20px;
    }

    .herobanner .innerbanner .right img {
      width: 65%;
    }

    .herobanner {
      padding: 60px 0 157px 0;
    }

    .herobanner .search-box {
      bottom: -162px;
    }

    .search-box {
      padding: 20px;
    }

    .search-box .col label {
      font-size: 14px;
      margin-bottom: 0;
    }

    .search-box .col select {
      font-size: 14px;
    }

    .form-btn {
      font-size: 15px;
      padding: 17px 8px;
    }

    h2 {
      font-size: 29px;
    }

    .about .inner-row .right h2 {
      width: 100%;
    }

    .par-bg:after {
      left: -6px;
      width: 123px;
      background-size: contain;
    }

    .readmore {
      font-size: 14px;
    }

    .partners .inner-roe .partners-logo {
      margin-top: 32px;
    }

    .pd100 {
      padding: 60px 0;
    }

    .profile:after {
      width: 241px;
      bottom: -11px;
      background-size: contain;
    }

    .about .inner-row .right {
      padding-left: 50px;
    }

    .about .inner-row .right p {
      padding-top: 12px;
      padding-bottom: 25px;
    }

    .slick-dotted.slick-slider {
      margin-bottom: 0 !important;
    }

    .main-btn {
      font-size: 14px;
      padding: 14px 34px;
    }

    .cta .inner-cta a {
      padding: 14px 23px;
    }

    .benefits .inner-row .top p {
      padding-top: 13px;
    }

    .courseBuddy:after {
      left: -5px;
      width: 188px;
      background-size: contain;
    }

    .benefits .inner-row .slick-slide {
      margin: 0 10px;
    }

    .benefits .inner-row .benefits-list .left p {
      font-size: 14px;
    }

    .benefits .inner-row .benefits-list .left h3 {
      margin-top: 9px;
      margin-bottom: 9px;
    }

    .benefits .inner-row .benefits-list .left h3 {
      font-size: 16px;
    }

    .benefits .inner-row .top {
      padding-bottom: 31px;
    }

    .benefits .inner-row .benefits-list .left {
      padding-top: 33px;
    }

    .h3 {
      font-size: 22px;
      line-height: 120%;
    }

    .one-profile .inner-row .name {
      margin-top: 15px;
      font-size: 15px;
    }

    .one-profile .inner-row .position {
      font-size: 14px;
    }

    .mission:after {
      left: -6px;
      width: 95px;
      bottom: -5px;
      background-size: contain;
    }

    .vision .inner-row .list .left p {
      width: 100%;
    }

    .vision .inner-row .list:first-child {
      padding-bottom: 50px;
    }

    p {
      line-height: 160%;
    }

    .mission-2:after {
      width: 114px;
      bottom: -5px;
      background-size: contain;
      left: -5px;
    }

    .vision .inner-row .list:last-child .left:first-child {
      padding-left: 20px;
    }

    .simplify .top h2 span {
      padding-left: 222px;
    }

    .simplify .top h2:before {
      left: 167px;
      width: 422px;
      top: -17px;
    }

    .simplify .list-type {
      margin-top: 24px;
      gap: 0 20px;
    }

    .simplify .list-type .list h3 {
      font-size: 18px;
    }

    .simplify .list-type .list ul {
      margin-top: 13px;
      margin-bottom: 50px;
    }

    .simplify .list-type .list {
      padding: 23px;
    }

    .simplify .list-type .list ul li {
      font-size: 14px;
    }

    .read-more-btn {
      font-size: 14px;
    }

    .cta {
      padding-top: 100px !important;
    }

    .cta .inner-cta h2 {
      max-width: 384px;
    }

    .cta .inner-cta {
      padding: 50px;
    }

    .word:after {
      bottom: -8px;
      left: -5px;
      width: 275px;
      background-size: contain;
    }

    .cta .inner-cta p {
      max-width: 477px;
      padding-right: 50px;
    }

    .cta .inner-cta .ctaimage {
      right: 33px;
      top: -55px;
      width: 44%;
    }

    #footer .foter-top {
      padding-bottom: 39px;
    }

    #footer .foter-top .righrbar ul li {
      margin-left: 20px;
    }

    a.my {
      font-size: 14px;
      padding: 0 20px;
      height: 45px;
      line-height: 45px;
      margin-right: 18px;
    }

    .afterlogin .login {
      font-size: 14px;
    }

    /*profile css*/
    .hero-profile .inner .names h2 {
      font-size: 22px;
    }

    .hero-profile .inner .names h3 {
      margin-top: 6px;
      font-size: 15px;
    }

    .hero-profile .inner .names {
      top: 31px;
    }

    .hero-profile {
      min-height: 150px;
    }

    #tabs-content .tab-content .innertab .cols.second-col .filed .email input,
    #tabs-content .tab-content .innertab .cols.second-col .filed .phone input {
      padding-left: 71px;
      background-position: center left 18px;
    }

    #tabs-content .tab-content .innertab .cols .heading h3 {
      font-size: 14px;
    }

    .form select {
      background-position: right 16px center;
    }

    .form input,
    .form select {
      padding: 0 16px;
    }

    .tabs ul#tabs-nav li a {
      font-size: 14px;
    }

    .tabs ul#tabs-nav li {
      padding: 8px 27px;
    }

    .hero-profile .inner .notifition {
      top: 38px;
    }

    #tabs-content {
      margin-top: 30px;
    }

    #tabs-content .tab-content .innertab .cols .heading {
      padding: 16px 25px;
    }

    .form label {
      font-size: 14px;
    }

    .form input::placeholder {
      font-size: 14px;
    }

    .btns .save {
      min-width: 180px;
      font-size: 15px;
    }

    .pdrl {
      padding: 16px 25px;
    }

    #tabs-content .tab-content .innertab .cols .filed {
      gap: 0 15px;
    }

    body.user-profile footer#footer {
      padding-top: 60px !important;
    }

    .hero-profile .inner .profile-section .imageprofile,
    .hero-profile .inner .profile-section .imageprofile:after {
      width: 120px;
      height: 120px;
    }

    .hero-profile .inner {
      top: 22px;
    }

    #tabs-content .tab-content .innertab .cols.third-col .fild {
      margin-bottom: 17px;
    }

    #tabs-content .tab-content .innertab .cols.second-col,
    #tabs-content .tab-content .innertab .cols.third-col {
      margin-top: 25px;
    }

    .hero-profile .inner .names {
      margin-left: 26px;
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  body .common-form-page .left-wrap .copyright-wrap {
    display: none;
  }

  body {
    .container {
      width: 98%;
      padding: 0 15px;
    }

    .header {
      .header-main {
        .logo {
          top: 0px;
        }
      }

      .menu-header-menu-container {
        height: 100%;
      }

      .header-right {
        padding: 0;
        width: 9% !important;
        // float: left !important;
        border-left: unset;
        order: 3;

        .nav-main {
          width: 100%;
          height: 100vh !important;
          position: fixed;
          left: -100%;
          top: 0px;
          padding-right: 0;
          overflow: hidden;
          @include transition(inherit);
          z-index: 9;
          display: none;
          -webkit-transition: all 0.25s ease 0s;
          -moz-transition: all 0.25s ease 0s;
          -ms-transition: all 0.25s ease 0s;
          transition: all 0.25s ease 0s;

          // &:after{
          //     position: absolute;
          //     left: 0;
          //     top: 0;
          //     width: 100%;
          //     height: auto;
          //     content: '';
          //     box-shadow: 0 0 0 100vh rgba(0, 0, 0, 0.6);
          // }
          ul.menu {
            // background: #fff url(../images/menu-sep.svg) no-repeat center bottom;
            background-color: #fff;
            padding: 100px 0;
            position: relative;
            z-index: 9;
            height: 100%;

            li {
              float: none;
              display: inline-block;
              width: 100%;
              height: auto;
              margin: 0;
              padding: 0px 20px !important;

              a {
                float: none;
                display: inline-block;
                height: auto;
                line-height: 110%;
                display: block;
                font-size: 16px;
                padding: 0;
                color: #333333;
                @include transition(inherit);

                &::after {
                  display: none;
                }

                &:hover {
                  color: #df091d;
                }
              }

              .hover {
                display: none;
              }

              ul.sub-menu {
                width: 100% !important;
                padding: 0px 0px 0 20px;
                background-color: transparent;
                visibility: visible;
                opacity: 1;
                position: relative;
                box-shadow: none;
                -webkit-transition: all 0s !important;
                transition: all 0s !important;
                top: 0;
                left: 0px;
                // height: 200px;
                overflow-y: scroll;

                &:before {
                  display: none;
                }

                li {
                  a {
                    border-bottom: unset;
                    padding-left: 0;
                    color: #000;
                    font-size: 14px;
                    font-weight: 400 !important;
                    text-transform: capitalize;
                  }
                }
              }

              span {
                display: inline-block;
                position: absolute;
                right: 12px;
                top: -15px;
                width: 50px;
                height: 50px;
                z-index: 111;
                background: url("../images/ic-menu-dropdown-arrow-black.svg") no-repeat;
                background-repeat: no-repeat;
                background-position: center;
                background-size: 15px;
                cursor: pointer;
                border: none;
                padding-bottom: 0;
              }

              &.menu-item-has-children {
                background-image: unset;
              }

              &.open {
                span {
                  transform: rotate(180deg);
                  -webkit-transition: all 0.5s;
                  transition: all 0.5s;
                }
              }
            }
          }
        }
      }
    }

    .nav_open {
      overflow: hidden;
    }

    .fixed .header .header-main .logo {
      width: 100%;
    }

    .fixed .header .header-main .logo a img {
      width: 82%;
    }

    .res_navigation {
      width: 30px;
      display: block;
      position: relative;
      right: 0;
      top: 0;
      height: 23px;
      margin-left: auto;
    }

    .nav-toggle.active {
      .bottom {
        -webkit-transform: translateY(-7px) translateX(0) rotate(-45deg);
        -ms-transform: translateY(-7px) translateX(0) rotate(-45deg);
        transform: translateY(-7px) translateX(0) rotate(-45deg);
        background: #333;
        top: 15px;
      }

      .top {
        -webkit-transform: translateY(12px) translateX(0) rotate(45deg);
        -ms-transform: translateY(12px) translateX(0) rotate(45deg);
        transform: translateY(12px) translateX(0) rotate(45deg);
        background: #333;
        top: -4px;
      }

      .middle {
        opacity: 0;
        background: #333;
      }
    }

    .search-box .col {
      padding-left: 25px;
      padding-right: 25px;
    }

    .fixed .link-menu {
      padding: 11px 17px;
    }

    .fixed .res_navigation {
      height: 24px;
    }

    .header .header-main .logo {
      width: 100%;
    }

    .header .header-right .nav-main ul.menu li ul.sub-menu li a {
      padding-bottom: 0;
    }

    .header .header-right .nav-main ul.menu li {
      padding: 0 30px;
      margin-bottom: 20px;
    }

    .header .header-right .nav-main ul.menu li.menu-item-has-children {
      background-position: right 13px center;
    }

    .rightsidebar .right-login .link-menu {
      width: 108px;
    }

    .header .header-main {
      display: flex;
    }

    .herobanner .innerbanner .left h1 {
      max-width: 269px;
    }

    .header .header-main .logo {
      z-index: 99;
    }

    .link-menu {
      padding: 12px 18px;
      font-size: 14px;
    }

    .header .header-right .nav-main ul.menu li a {
      font-size: 14px;
    }

    .header .header-right .nav-main ul.menu li ul.sub-menu li a {
      font-size: 14px;
    }

    section {
      margin-top: 79px;
    }

    #header {
      padding: 12px 0;
    }

    h1 {
      font-size: 25px;
    }

    .herobanner .innerbanner .left p {
      max-width: 538px;
    }

    .herobanner .innerbanner .left p a {
      font-size: 14px;
    }

    p {
      font-size: 14px;
    }

    .application:after {
      width: 129px;
      bottom: -16px;

      background-size: contain;
    }

    .herobanner .innerbanner .left p {
      padding-top: 14px;
    }

    .herobanner .innerbanner .left {
      padding-top: 20px;
    }

    .herobanner .innerbanner .right img {
      width: 85%;
    }

    .search-box .col:nth-child(4) {
      border-right: 1px solid #e5e5e5;
    }

    .search-box .col:nth-child(2),
    .search-box .col:nth-child(4) {
      border: unset;
    }

    .search-box .col:nth-child(3) {
      padding-left: 0;
    }

    .search-box .col:last-child {
      grid-column: 1/3;
      padding-left: 0;
    }

    .herobanner {
      padding: 60px 0 157px 0;
    }

    .herobanner .search-box {
      position: absolute;
      width: 100%;

      left: 0;
    }

    .herobanner .search-box {
      bottom: -182px;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 14px 0;
    }

    .search-box {
      padding: 20px;
    }

    .search-box .col label {
      font-size: 14px;
      margin-bottom: 0;
    }

    .search-box .col select {
      font-size: 14px;
    }

    .form-btn {
      font-size: 15px;
      padding: 17px 8px;
    }

    h2 {
      font-size: 20px;
    }

    .about .inner-row .right h2 {
      width: 100%;
    }

    .par-bg:after {
      left: -6px;
      width: 90px;
      background-size: contain;
    }

    .readmore {
      font-size: 14px;
    }

    .partners .inner-roe .partners-logo {
      margin-top: 22px;
    }

    .pd100 {
      padding: 50px 0;
    }

    .profile:after {
      width: 171px;
      bottom: -11px;
      background-size: contain;
    }

    .about .inner-row .right {
      padding-left: 50px;
    }

    .about .inner-row .right p {
      padding-top: 12px;
      padding-bottom: 25px;
    }

    .slick-dotted.slick-slider {
      margin-bottom: 0 !important;
    }

    .main-btn {
      font-size: 14px;
      padding: 14px 34px;
    }

    .cta .inner-cta a {
      padding: 14px 23px;
    }

    .benefits .inner-row .top p {
      padding-top: 13px;
    }

    .courseBuddy:after {
      left: -5px;
      width: 131px;
      background-size: contain;
    }

    .benefits .inner-row .slick-slide {
      margin: 0 10px;
    }

    .benefits .inner-row .benefits-list .left p {
      font-size: 14px;
    }

    .benefits .inner-row .benefits-list .left h3 {
      margin-top: 9px;
      margin-bottom: 9px;
    }

    .benefits .inner-row .benefits-list .left h3 {
      font-size: 16px;
    }

    .benefits .inner-row .top {
      padding-bottom: 20px;
    }

    .benefits .inner-row .benefits-list .left {
      padding-top: 13px;
    }

    .h3 {
      font-size: 22px;
      line-height: 120%;
    }

    .one-profile .inner-row .name {
      margin-top: 4px;
      font-size: 15px;
    }

    .one-profile .inner-row .position {
      font-size: 14px;
      line-height: 18px;
    }

    .mission:after {
      left: -6px;
      width: 69px;
      bottom: -6px;
      background-size: contain;
    }

    .vision .inner-row .list .left p {
      width: 100%;
    }

    .vision .inner-row .list:first-child {
      padding-bottom: 30px;
    }

    p {
      line-height: 160%;
    }

    .mission-2:after {
      width: 80px;
      bottom: -6px;
      background-size: contain;
      left: -5px;
    }

    .vision .inner-row .list:last-child .left:first-child {
      padding-left: 0px;
    }

    .simplify .top h2 span {
      padding-left: 222px;
    }

    .simplify .top h2:before {
      left: 117px;
      width: 358px;
      top: -17px;
    }

    .simplify .top p {
      width: 100%;
    }

    .simplify .list-type {
      margin-top: 24px;
      gap: 20px;
      grid-template-columns: repeat(2, 1fr);
    }

    .simplify .list-type .list h3 {
      font-size: 18px;
    }

    .simplify .list-type .list ul {
      margin-top: 13px;
      margin-bottom: 50px;
    }

    .simplify .list-type .list {
      padding: 23px;
    }

    .simplify .list-type .list ul li {
      font-size: 14px;
    }

    .read-more-btn {
      font-size: 14px;
    }

    .cta {
      padding-top: 40px !important;
    }

    .cta .inner-cta h2 {
      max-width: 264px;
    }

    .cta .inner-cta {
      padding: 50px;
    }

    .word:after {
      bottom: -8px;
      left: -5px;
      width: 190px;
      background-size: contain;
    }

    .cta .inner-cta p {
      max-width: 477px;
      padding-right: 150px;
      margin-top: 12px;
      margin-bottom: 12px;
    }

    .cta .inner-cta .ctaimage {
      right: 33px;
      top: -31px;
      width: 44%;
    }

    #footer .foter-top {
      padding-bottom: 19px;
    }

    #footer .foter-top .righrbar ul li {
      margin-left: 15px;
    }

    a.my {
      font-size: 14px;
      padding: 0 20px;
      height: 45px;
      line-height: 45px;
      margin-right: 14px;
      width: 137px;
    }

    .afterlogin .login {
      font-size: 14px;
    }

    /*profile css*/
    .hero-profile .inner .names h2 {
      font-size: 22px;
    }

    .hero-profile .inner .names h3 {
      margin-top: 6px;
      font-size: 15px;
    }

    .hero-profile .inner .names {
      top: 31px;
    }

    .hero-profile {
      min-height: 150px;
    }

    #tabs-content .tab-content .innertab .cols.second-col .filed .email input,
    #tabs-content .tab-content .innertab .cols.second-col .filed .phone input {
      padding-left: 71px;
      background-position: center left 18px;
    }

    #tabs-content .tab-content .innertab .cols .heading h3 {
      font-size: 14px;
    }

    .form select {
      background-position: right 16px center;
    }

    .form input,
    .form select {
      padding: 0 16px;
    }

    .tabs ul#tabs-nav li a {
      font-size: 14px;
    }

    .tabs ul#tabs-nav li {
      padding: 8px 17px;
    }

    #tabs-content .tab-content .innertab .cols .filed {
      grid-template-columns: repeat(2, 1fr);
      gap: 15px;
    }

    .hero-profile .inner .notifition {
      top: 38px;
    }

    #tabs-content {
      margin-top: 30px;
    }

    #tabs-content .tab-content .innertab .cols .heading {
      padding: 16px 20px;
    }

    .form label {
      font-size: 14px;
    }

    .form input::placeholder {
      font-size: 14px;
    }

    .btns .save {
      min-width: 180px;
      font-size: 15px;
    }

    .pdrl {
      padding: 16px 20px;
    }

    body.user-profile footer#footer {
      padding-top: 50px !important;
    }

    .hero-profile .inner .profile-section .imageprofile,
    .hero-profile .inner .profile-section .imageprofile:after {
      width: 120px;
      height: 120px;
    }

    .hero-profile .inner {
      top: 22px;
    }

    #tabs-content .tab-content .innertab .cols.third-col .fild {
      margin-bottom: 17px;
    }

    #tabs-content .tab-content .innertab .cols.second-col,
    #tabs-content .tab-content .innertab .cols.third-col {
      margin-top: 25px;
    }

    .hero-profile .inner .names {
      margin-left: 26px;
    }
  }
}

@media (max-width: 767px) {
  body {
    .container {
      width: 98%;
      padding: 0 15px;
    }

    .header {
      .header-main {
        .logo {
          top: 0px;
        }
      }

      .menu-header-menu-container {
        height: 100%;
      }

      .header-right {
        padding: 0;
        width: 9% !important;
        // float: left !important;
        border-left: unset;
        order: 3;
        margin-left: 20px;

        .nav-main {
          width: 100%;
          height: 100vh !important;
          position: fixed;
          left: -100%;
          top: 0px;
          padding-right: 0;
          overflow: hidden;
          @include transition(inherit);
          z-index: 9;
          display: none;
          -webkit-transition: all 0.25s ease 0s;
          -moz-transition: all 0.25s ease 0s;
          -ms-transition: all 0.25s ease 0s;
          transition: all 0.25s ease 0s;

          // &:after{
          //     position: absolute;
          //     left: 0;
          //     top: 0;
          //     width: 100%;
          //     height: auto;
          //     content: '';
          //     box-shadow: 0 0 0 100vh rgba(0, 0, 0, 0.6);
          // }
          ul.menu {
            // background: #fff url(../images/menu-sep.svg) no-repeat center bottom;
            background-color: #fff;
            padding: 100px 0;
            position: relative;
            z-index: 9;
            height: 100%;

            li {
              float: none;
              display: inline-block;
              width: 100%;
              height: auto;
              margin: 0 !important;
              margin-bottom: 20px !important;
              padding: 0px 20px !important;

              a {
                float: none;
                display: inline-block;
                height: auto;
                line-height: 110%;
                display: block;
                font-size: 16px;
                padding: 0;
                color: #333333;
                @include transition(inherit);

                &::after {
                  display: none;
                }

                &:hover {
                  color: #009DA5;
                }
              }

              .hover {
                display: none;
              }

              ul.sub-menu {
                width: 100% !important;
                padding: 0px 0px 0 20px;
                background-color: transparent;
                visibility: visible;
                opacity: 1;
                position: relative;
                box-shadow: none;
                -webkit-transition: all 0s !important;
                transition: all 0s !important;
                top: 0;
                left: 0px;
                // height: 200px;
                overflow-y: scroll;

                &:before {
                  display: none;
                }

                li {
                  a {
                    border-bottom: unset;
                    padding-left: 0;
                    color: #000;
                    font-size: 14px;
                    font-weight: 400 !important;
                    text-transform: capitalize;
                  }
                }
              }

              span {
                display: inline-block;
                position: absolute;
                right: 12px;
                top: -17px;
                width: 50px;
                height: 50px;
                z-index: 111;
                background: url("../images/ic-menu-dropdown-arrow-black.svg") no-repeat;
                background-repeat: no-repeat;
                background-position: center;
                background-size: 15px;
                cursor: pointer;
                border: none;
                padding-bottom: 0;
              }

              &.menu-item-has-children {
                background-image: unset;
              }

              &.open {
                span {
                  transform: rotate(180deg);
                  -webkit-transition: all 0.5s;
                  transition: all 0.5s;
                }
              }
            }
          }
        }
      }
    }

    .herobanner .search-box {
      bottom: 0;
      display: block;
      position: relative;
    }

    .nav_open {
      overflow: hidden;
    }

    .fixed .header .header-main .logo {
      width: 100%;
    }

    .fixed .header .header-main .logo a img {
      width: 120px;
    }

    .res_navigation {
      width: 30px;
      display: block;
      position: relative;
      right: 0;
      top: 0;
      height: 30px;
      margin-left: auto;
    }

    .nav-toggle.active {
      .bottom {
        -webkit-transform: translateY(-7px) translateX(0) rotate(-45deg);
        -ms-transform: translateY(-7px) translateX(0) rotate(-45deg);
        transform: translateY(-7px) translateX(0) rotate(-45deg);
        background: #333;
        top: 15px;
      }

      .top {
        -webkit-transform: translateY(12px) translateX(0) rotate(45deg);
        -ms-transform: translateY(12px) translateX(0) rotate(45deg);
        transform: translateY(12px) translateX(0) rotate(45deg);
        background: #333;
        top: -4px;
      }

      .middle {
        opacity: 0;
        background: #333;
      }
    }

    .search-box .col {
      padding-left: 25px;
      padding-right: 25px;
    }

    .fixed .link-menu {
      padding: 8px 12px;
    }

    .fixed .res_navigation {
      height: 26px;
    }

    .fixed .res_navigation {
      top: 0;
    }

    .header .header-main .logo {
      width: 100%;
    }

    .header .header-right .nav-main ul.menu li ul.sub-menu li a {
      padding-bottom: 0;
    }

    .header .header-main .logo a img {
      width: 140px;
    }

    .header .header-right .nav-main ul.menu li {
      padding: 0 20px;
      margin-bottom: 20px;
    }

    .header .header-right .nav-main ul.menu li.menu-item-has-children {
      background-position: right 13px center;
    }

    .rightsidebar .right-login .link-menu {
      width: 98px;
    }

    .header .header-main {
      display: flex;
    }

    .herobanner .innerbanner .left h1 {
      max-width: 269px;
    }

    .header .header-main .logo {
      z-index: 99;
    }

    .link-menu {
      padding: 8px 12px;
      font-size: 14px;
    }

    .header .header-right .nav-main ul.menu li a {
      font-size: 14px;
    }

    .header .header-right .nav-main ul.menu li ul.sub-menu li a {
      font-size: 14px;
    }

    section {
      margin-top: 70px;
    }

    #header {
      padding: 12px 0;
    }

    h1 {
      font-size: 25px;
    }

    .herobanner .innerbanner .left p {
      max-width: 538px;
    }

    .herobanner .innerbanner .left p a {
      font-size: 14px;
    }

    p {
      font-size: 14px;
    }

    .application:after {
      width: 129px;
      bottom: -16px;

      background-size: contain;
    }

    .herobanner .innerbanner .left p {
      padding-top: 14px;
    }

    .herobanner .innerbanner .left {
      padding-top: 20px;
    }

    .herobanner .innerbanner .right img {
      width: 85%;
    }

    .search-box .col:nth-child(4) {
      border: unset;
    }

    .search-box .col:last-child {
      padding-left: 0;
    }

    .herobanner {
      padding: 60px 0 0 0;
    }

    .herobanner .search-box {
      position: absolute;
      width: 100%;

      left: 0;
    }

    .herobanner .search-box {
      bottom: 0;
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      position: relative;
    }

    .herobanner .search-box {
      margin-top: 40px;
    }

    .search-box .col {
      padding-left: 0px;
      padding-right: 0px;
    }

    .search-box .col {
      border-right: unset !important;
      margin-bottom: 10px;
    }

    .search-box {
      padding: 20px;
    }

    .search-box .col label {
      font-size: 14px;
      margin-bottom: 0;
    }

    .search-box .col select {
      font-size: 14px;
    }

    .form-btn {
      font-size: 15px;
      padding: 17px 8px;
    }

    h2 {
      font-size: 20px;
    }

    .about .inner-row .right h2 {
      width: 100%;
    }

    .par-bg:after {
      left: -6px;
      width: 90px;
      background-size: contain;
    }

    .readmore {
      font-size: 14px;
    }

    .partners .inner-roe .partners-logo {
      margin-top: 22px;
    }

    .pd100 {
      padding: 50px 0;
    }

    .profile:after {
      width: 171px;
      bottom: -11px;
      background-size: contain;
    }

    .about .inner-row .right {
      padding-left: 50px;
    }

    .about .inner-row .right p {
      padding-top: 12px;
      padding-bottom: 25px;
    }

    .slick-dotted.slick-slider {
      margin-bottom: 0 !important;
    }

    .main-btn {
      font-size: 14px;
      padding: 14px 34px;
    }

    .cta .inner-cta a {
      padding: 14px 23px;
    }

    .benefits .inner-row .top p {
      padding-top: 13px;
    }

    .courseBuddy:after {
      left: -5px;
      width: 131px;
      background-size: contain;
    }

    .benefits .inner-row .slick-slide {
      margin: 0 10px;
    }

    .benefits .inner-row .benefits-list .left p {
      font-size: 14px;
    }

    .benefits .inner-row .benefits-list .left h3 {
      margin-top: 9px;
      margin-bottom: 9px;
    }

    .benefits .inner-row .benefits-list .left h3 {
      font-size: 16px;
    }

    .benefits .inner-row .top {
      padding-bottom: 20px;
    }

    .benefits .inner-row .benefits-list .left {
      padding-top: 13px;
    }

    .h3 {
      font-size: 22px;
      line-height: 120%;
    }

    .one-profile .inner-row .name {
      margin-top: 4px;
      font-size: 15px;
    }

    .one-profile .inner-row .position {
      font-size: 14px;
      line-height: 18px;
    }

    .mission:after {
      left: -6px;
      width: 69px;
      bottom: -6px;
      background-size: contain;
    }

    .vision .inner-row .list .left p {
      width: 100%;
    }

    .vision .inner-row .list:first-child {
      padding-bottom: 30px;
    }

    p {
      line-height: 160%;
    }

    .mission-2:after {
      width: 80px;
      bottom: -6px;
      background-size: contain;
      left: -5px;
    }

    .vision .inner-row .list:last-child .left:first-child {
      padding-left: 0px;
    }

    .simplify .top h2 span {
      padding-left: 222px;
    }

    .simplify .top h2:before {
      left: 117px;
      width: 358px;
      top: -17px;
    }

    .simplify .top p {
      width: 100%;
    }

    .simplify .list-type {
      margin-top: 24px;
      gap: 20px;
      grid-template-columns: repeat(1, 1fr);
    }

    .simplify .list-type .list h3 {
      font-size: 18px;
    }

    .simplify .list-type .list ul {
      margin-top: 13px;
      margin-bottom: 20px;
    }

    .simplify .list-type .list {
      padding: 23px;
    }

    .simplify .list-type .list ul li {
      font-size: 14px;
    }

    .read-more-btn {
      font-size: 14px;
    }

    .cta {
      padding-top: 40px !important;
    }

    .cta .inner-cta h2 {
      max-width: 264px;
    }

    .cta .inner-cta {
      padding: 50px;
    }

    .word:after {
      bottom: -8px;
      left: -5px;
      width: 190px;
      background-size: contain;
    }

    .cta .inner-cta p {
      max-width: 477px;
      padding-right: 150px;
      margin-top: 12px;
      margin-bottom: 12px;
    }

    .cta .inner-cta .ctaimage {
      right: 33px;
      top: -31px;
      width: 44%;
    }

    #footer .foter-top {
      padding-bottom: 19px;
    }

    #footer .foter-top .righrbar ul li {
      margin-left: 15px;
    }

    // .search-box .col:first-child {
    //     padding-left: 0;
    //     grid-column: 1 / span 2;
    // }
    // .search-box .col:nth-child(2) {
    //     padding-left: 0;
    //     grid-column: 1 / span 2;
    // }
    // .search-box .col:nth-child(3) {
    //     padding-left: 0;
    //     grid-column: 1 / span 2;
    // }
    // .search-box .col:nth-child(4) {
    //     padding-left: 0;
    //     grid-column: 1 / span 2;
    // }
    // .search-box .col:nth-child(5) {
    //     padding-left: 0;
    //     grid-column: 1 / span 2;
    // }
    // .herobanner .search-box {

    //     grid-template-columns: auto auto auto auto !important;

    // }
    .simplify .top h2:before {
      display: none;
    }

    .simplify .top h2 span {
      padding-left: 20px;
    }

    .cta .inner-cta {
      padding: 20px;
    }

    .cta .inner-cta .ctaimage {
      display: none;
    }

    .cta .inner-cta h2 {
      max-width: 100%;
      text-align: center;
    }

    .cta .inner-cta p {
      max-width: 100%;
      padding-right: 0;
      text-align: center;
    }

    .word:after {
      display: none;
    }

    .cta .inner-cta {
      align-items: center;
    }

    .res_navigation {
      top: 4px;
    }

    a.my {
      font-size: 14px;
      padding: 0 20px;
      height: 45px;
      line-height: 45px;
      margin-right: 14px;
      width: 137px;
    }

    .afterlogin .login {
      font-size: 14px;
    }

    .link-menu {
      border-radius: 5px;
    }

    /*profile css*/
    .hero-profile .inner .names h2 {
      font-size: 17px;
    }

    .hero-profile .inner .names h3 {
      margin-top: 6px;
      font-size: 15px;
    }

    .hero-profile .inner .names {
      top: 31px;
    }

    .hero-profile {
      min-height: 150px;
    }

    #tabs-content .tab-content .innertab .cols.second-col .filed .email input,
    #tabs-content .tab-content .innertab .cols.second-col .filed .phone input {
      padding-left: 71px;
      background-position: center left 18px;
    }

    #tabs-content .tab-content .innertab .cols .heading h3 {
      font-size: 14px;
    }

    .form select {
      background-position: right 16px center;
    }

    .form input,
    .form select {
      padding: 0 13px;
    }

    .tabs ul#tabs-nav li a {
      font-size: 13px;
      line-height: 17px;
    }

    .tabs ul#tabs-nav li {
      padding: 8px 7px;
    }

    #tabs-content .tab-content .innertab .cols .filed {
      grid-template-columns: repeat(2, 1fr);
      gap: 15px;
    }

    .hero-profile .inner .notifition {
      top: 38px;
    }

    .tabs ul#tabs-nav li {
      text-align: center;
    }

    #tabs-content {
      margin-top: 30px;
    }

    #tabs-content .tab-content .innertab .cols .heading {
      padding: 16px 20px;
    }

    .form label {
      font-size: 13px;
    }

    .form input::placeholder {
      font-size: 13px;
    }

    .btns .save {
      min-width: 180px;
      font-size: 15px;
    }

    .pdrl {
      padding: 16px 20px;
    }

    body.user-profile footer#footer {
      padding-top: 50px !important;
    }

    .hero-profile .inner .profile-section .imageprofile,
    .hero-profile .inner .profile-section .imageprofile:after {
      width: 120px;
      height: 120px;
    }

    .hero-profile .inner {
      top: 22px;
    }

    #tabs-content .tab-content .innertab .cols.third-col .fild {
      margin-bottom: 17px;
    }

    #tabs-content .tab-content .innertab .cols.second-col,
    #tabs-content .tab-content .innertab .cols.third-col {
      margin-top: 25px;
    }

    .hero-profile .inner .names {
      margin-left: 26px;
    }
  }
}

@media only screen and (max-device-width: 579px) {
  body {
    .herobanner .innerbanner .left {
      padding-top: 0;
    }

    html {
      -webkit-text-size-adjust: none;
    }

    a.my {
      display: none;
      font-size: 12px;
      padding: 0 10px;
      height: 35px;
      line-height: 35px;
      margin-right: 8px;
      width: 104px;
      border-radius: 4px;
    }

    .afterlogin .login {
      font-size: 12px;
    }

    .herobanner .innerbanner {
      grid-template-columns: repeat(1, 1fr);
    }

    .herobanner .innerbanner .left {
      order: 2;
    }

    .herobanner .innerbanner .right {
      order: 1;
      margin-bottom: 30px;
    }

    .herobanner {
      padding: 50px 0 157px 0;
    }

    .herobanner .search-box {
      bottom: 0;
      display: block;

      position: relative;
    }

    .search-box .col {
      border-right: unset;
      padding-left: 0px;
      padding-right: 0px;
      margin-bottom: 10px;
    }

    .herobanner .search-box {
      margin-top: 40px;
    }

    .herobanner .innerbanner .left h1 {
      max-width: 100%;
      text-align: center;
      padding: 0 50px;
    }

    .herobanner .innerbanner .left p {
      text-align: center;
      max-width: 100%;
    }

    .herobanner {
      padding: 50px 0 0px 0;
    }

    .partners .inner-roe .partners-logo .slick-slide {
      margin: 0 20px;
    }

    .about .inner-row {
      display: flex;
      align-items: center;
      flex-direction: column;
    }

    .about .inner-row .right {
      padding-left: 0;
      padding-top: 30px;
    }

    .about .inner-row .left,
    .about .inner-row .right {
      width: 100%;
    }

    .about .inner-row .right p {
      padding-top: 12px;
      padding-bottom: 12px;
    }

    .benefits .inner-row .benefits-list .left {
      text-align: center;
    }

    .benefits .inner-row .benefits-list .left {
      padding-top: 23px;
    }

    .benefits .inner-row .slick-slide {
      margin: 0;
    }

    .benefits .inner-row .benefits-list .left img {
      margin: auto;
    }

    .benefits .inner-row .benefits-list .left p {
      max-width: 100%;
    }

    .slick-arrow.slick-prev {
      left: -6px;
    }

    .one-profile .inner-row h2 {
      text-align: center;
    }

    .h3 {
      font-size: 19px;
    }

    .one-profile .inner-row .name {
      font-size: 14px;
      text-align: center;
    }

    .one-profile .inner-row .position {
      text-align: center;
    }

    .vision .inner-row .list:first-child {
      padding-bottom: 40px;
    }

    .vision .inner-row .list:first-child .left:first-child {
      text-align: center;
    }

    .vision .inner-row .list:last-child .left:first-child {
      text-align: center;
    }

    .vision .inner-row .list {
      grid-template-columns: repeat(1, 1fr);
      gap: 20px;
    }

    .vision .inner-row .list:first-child .left:first-child {
      order: 2;
    }

    .simplify .top p {
      padding-top: 16px;
    }

    .hero-profile .inner .profile-section .imageprofile,
    .hero-profile .inner .profile-section .imageprofile:after {
      width: 100px;
      height: 100px;
    }

    .hero-profile .inner .profile-section .imageprofile a {
      bottom: -5px;
    }

    #tabs-content .tab-content .innertab .cols .filed {
      grid-template-columns: repeat(1, 1fr);
      gap: 15px;
    }

    #tabs-content .tab-content .innertab .cols.second-col .filed {
      grid-template-columns: repeat(1, 1fr);
    }

    .hero-profile {
      min-height: 110px;
    }

    .hero-profile .inner .names h3 {
      font-size: 14px;
    }

    .hero-profile .inner .names {
      margin-left: 11px;
    }

    .hero-profile .inner .names {
      top: 10px;
    }

    .hero-profile .inner .notifition {
      top: 14px;
    }

    /*about css*/
    .about-hero .innerbanner .left h2 {
      text-align: center;
    }

    .vision .inner-row .list:first-child .left:first-child {
      order: 1;
    }

    .vision .inner-row .list:first-child .left:last-child {
      order: 2;
    }

    .about-vision .inner-row .list:last-child .left:last-child {
      order: 1;
    }

    .about-vision .inner-row .list:last-child .left:first-child {
      text-align: left;
    }

    .our-value .innerrow .innerlist {
      grid-template-columns: repeat(1, 1fr);
      gap: 20px;
    }

    .our-value .innerrow .innerlist .left h3 {
      padding-top: 14px;
      padding-bottom: 9px;
      font-size: 17px;
    }

    /*education css*/
    .education .list-education .left {
      padding-top: 18px;
    }

    .education h2 {
      font-size: 16px;
    }

    /*country css*/
    .country .innerrow .list-country .left h3 {
      font-size: 13px;
      margin-left: 14px;
    }

    .country .innerrow .list-country .left {

      height: 50px;
      line-height: 50px;
    }

    .country .innerrow .list-country {

      gap: 15px;
    }

    .country .heading {
      margin-bottom: 50px;
    }

    .country .innerrow .btn {
      margin-top: 20px;
    }

    .country .innerrow .list-country {
      grid-template-columns: repeat(1, 1fr);
    }

    .country .heading {
      flex-wrap: nowrap;
      justify-content: space-between;
      align-items: flex-end;
      flex-direction: column;
    }

    .country .heading h2 {
      order: 2;
      text-align: center;
      padding: 0 30px;
      width: 100%;
    }

    .country .heading .main-btn {
      order: 1;
      margin-bottom: 20px;
    }
  }
}

@media only screen and (max-device-width: 425px) {
  .tabs ul#tabs-nav {
    flex-direction: column;
  }

  .tabs ul#tabs-nav li.active:after {
    display: none;
  }

  .tabs ul#tabs-nav li.active {
    border-bottom: 1px solid #e5e5e5;
  }
}

@media only screen and (max-device-width: 375px) {
  .herobanner .innerbanner .left h1 {
    padding: 0 10px;
  }

  .country .innerrow .list-country {
    grid-template-columns: repeat(1, 1fr);
  }
}

@media only screen and (max-device-width: 320px) {
  .profile:after {
    display: none;
  }

  .hero-profile .inner .notifition {
    top: 40px;
  }
}

@media only screen and (min-width:580px) and (max-width:648px) {

  body {
    .country .heading {
      flex-wrap: nowrap;
      justify-content: space-between;
      align-items: flex-end;
      flex-direction: column;
    }

    .country .heading h2 {
      order: 2;
      text-align: center;
      padding: 0 30px;
      width: 100%;
    }

    .country .heading .main-btn {
      order: 1;
      margin-bottom: 20px;
    }
  }
}