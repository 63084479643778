#footer{
    padding-bottom: 0 !important
    ;
    display: flex;
    .foter-top{
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 75px;
        .righrbar{
            ul{
                display: flex;
                li{
                    margin-left: 30px;
                }
            }
        }
    }
    #copyright{
        display: flex;
        border-top: 1px solid #E5E5E5;
        padding: 25px 0;
        p{
            color: #555;
            text-align: center;
            font-size: 15px;
            font-style: normal;
            font-weight: 400;
            line-height: 18px;
            padding-bottom: 0;
        }
    }
}